<template>
  <AuthComp header="Welcome To Digital Classroom">
    <InputLogin />
  </AuthComp>
</template>
<script>
import InputLogin from '@/components/app/InputLogin'
import config from '@/config'
export default {
  name: 'Login',
  components: {
    InputLogin,
    AuthComp: () => import('@/components/app/AuthComp'),
  },
  created() {
    this.logo = config.school.logo
  },
  data() {
    return {
      logo: '',
    }
  },
}
</script>
